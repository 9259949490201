.root {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: calc(100vh - var(--nav-height));
  }
  
  .main {
    max-width: 640px;
    width: 100%;
    position: relative;
    display: flex;
    flex-direction: column;
    padding: 24px;
    padding-top: 48px;
    flex: 1 1;
    align-self: center;
  }
  
  .title {
    font-size: 2rem;
    font-weight: 700;
    text-align: center;
    margin-bottom: 0;
  }
  
  .subtitle {
    font-size: 1rem;
    color: var(--secondary);
    text-align: center;
    line-height: 1.25;
  }
  
  .submit {
    width: 100%;
  }
  