.log-container {
    background-color: #333;
    color: #fff;
    padding: 10px;
    font-family: 'Courier New', monospace;
  }
  
  .log-header {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
  }
  
  .log-content {
    overflow-y: auto;
  }
  
  .log-entry {
    padding: 5px;
    border-bottom: 1px solid #555;
  }
  
  .log-entry:last-child {
    border-bottom: none;
  }
  
  .log-success {
    color: #00ff00;
  }
  
  .log-error {
    color: #ff0000;
  }
  
  .log-timestamp {
    margin-right: 10px;
  }
  
  .log-ip {
    margin-right: 10px;
  }
  
  .log-method {
    margin-right: 10px;
  }
  
  .log-url {
    margin-right: 10px;
  }
  
  .log-status {
    margin-right: 10px;
  }
  
  .log-error-message {
    color: #ff0000;
  }
  
  .log-response {
    color: #00ff00;
  }

  .scroll::-webkit-scrollbar {
    width: 3px;
    top: 20;
}

.scroll::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.3); 
    border-radius: 10px;
}

.scroll::-webkit-scrollbar-thumb {
    border-radius: 10px;
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5); 
}