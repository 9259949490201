@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
      'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
      sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }

  .utility-name {
    text-transform: capitalize;
  }
  

  .progress {
    animation: progress 1s infinite linear;
  }
  
  .left-right {
      transform-origin: 0% 50%;
  }
      @keyframes progress {
      0% {
          transform:  translateX(0) scaleX(0);
      }
      40% {
          transform:  translateX(0) scaleX(0.4);
      }
      100% {
          transform:  translateX(100%) scaleX(0.5);
      }
  }